import React from 'react'
import { useInView } from 'react-intersection-observer'
import './LazySection.sass'

const LazySection = ({ className, children, inViewCallback, ...rest }) => {
  const [refLazy, inViewLazy] = useInView({ triggerOnce: true, rootMargin: '75%' })
  const [refReveal, inViewReveal] = useInView({ triggerOnce: true, rootMargin: '-150px 0px' })

  if (!className || className.split(' ').indexOf('is-lazy-section') <= -1) {
    className = `${className || ''} is-lazy-section`
  }
  if (!inViewLazy && (!className || className.split(' ').indexOf('is-lazy-section-hidden') <= -1)) {
    className = `${className || ''} is-lazy-section-hidden`
  }
  if (inViewReveal && (!className || className.split(' ').indexOf('is-lazy-section-reveal') <= -1)) {
    className = `${className || ''} is-lazy-section-reveal`
  }

  React.useEffect(() => {
    inViewCallback && inViewCallback(inViewReveal)
  }, [inViewReveal, inViewCallback])

  return (
    <div ref={refReveal}>
      <section ref={refLazy} className={className} {...rest}>
        {children}
      </section>
    </div>
  )
}

export default LazySection

// const LazySection = ({ className, ...rest }) => {
//   const [isVisible, setIsVisible] = React.useState(false)
//   const [elRef, setElRef] = React.useState()

//   React.useEffect(() => {
//     let observer
//     let didCancel = false

//     if (elRef && !isVisible) {
//       if (window.IntersectionObserver) {
//         observer = new window.IntersectionObserver(
//           entries => {
//             entries.forEach(entry => {
//               // when image is visible in the viewport + rootMargin
//               if (
//                 !didCancel &&
//                 (entry.intersectionRatio > 0 || entry.isIntersecting)
//               ) {
//                 setIsVisible(true)
//               }
//             })
//           },
//           {
//             threshold: 0.01,
//             rootMargin: '75%'
//           }
//         )
//         observer.observe(elRef)
//       } else {
//         // Old browsers fallback
//         setIsVisible(true)
//       }
//     }
//     return () => {
//       didCancel = true
//       // on component unmount, we remove the listner
//       if (observer && observer.unobserve) {
//         observer.unobserve(elRef)
//       }
//     }
//   }, [elRef, isVisible])

//   if (!className || className.split(' ').indexOf('is-lazy-section') <= -1) {
//     className = `${className || ''} is-lazy-section`
//   }
//   if (!isVisible && (!className || className.split(' ').indexOf('is-lazy-section-hidden') <= -1)) {
//     className = `${className || ''} is-lazy-section-hidden`
//   }

//   return <section ref={setElRef} className={className} {...rest} />
// }

// export default LazySection
