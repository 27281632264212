import React from 'react'
import { Modal, LazySection } from '..'
import { RichTextField, FirstParagraph } from '../prismic-elements'

import './Quote.sass'

const Quote = ({ slice }) => {
  const [isModalShown, setIsModalShown] = React.useState(false)

  return (
    <LazySection className={`section section-cards-full slice-quote ${slice.slice_label || ''}`}>
      <Modal className='modal-quote' isShown={isModalShown} hide={() => setIsModalShown(false)}>
        <div className='modal-quote-inner'>
          {
            slice.primary.image.url &&
              <img
                src={slice.primary.image.url}
                loading='lazy'
                alt={slice.primary.image.alt}
                width={slice.primary.image.dimensions.width}
                height={slice.primary.image.dimensions.height}
              />
          }
          <RichTextField field={slice.primary.description} Component='div' className='modal-quote-description' />
        </div>
      </Modal>
      <div className='is-full w-container'>
        <div className='section-cards-full-container'>
          <div
            className='section-cards-full-item'
          >
            {
              slice.primary.image.url &&
                <img
                  src={slice.primary.image.url}
                  loading='lazy'
                  alt={slice.primary.image.alt}
                  width={slice.primary.image.dimensions.width}
                  height={slice.primary.image.dimensions.height}
                />
            }
            <RichTextField field={slice.primary.title} Component='h3' className='section-cards-full-item-subtitle' />
            <h2 className='section-cards-full-item-title'>
              <FirstParagraph richText={slice.primary.description} textLimit={140} />
            </h2>
            <button
              className='section-cards-full-item-link w-inline-block'
              onClick={() => setIsModalShown(true)}
            >
              <div>{slice.primary.cta_label}</div>
            </button>
          </div>
        </div>
      </div>
    </LazySection>
  )
}

export default Quote
