import React from 'react'
import useScrollData from '../utils/useScrollData'

import './Carousel.sass'

const Carousel = ({ children, className, pagerClassName, pagerItemClassName, isSlideShow, slideShowInterval, ...rest }) => {
  if (!className || className.split(' ').indexOf('carousel') <= -1) {
    className = `${className || ''} carousel`
  }
  if (!className || className.split(' ').indexOf('is-scroll-snap-center') <= -1) {
    className = `${className || ''} is-scroll-snap-center`
  }
  if (!pagerClassName || pagerClassName.split(' ').indexOf('carousel-pager') <= -1) {
    pagerClassName = `${pagerClassName || ''} carousel-pager`
  }
  if (!pagerItemClassName || pagerItemClassName.split(' ').indexOf('carousel-pager-item') <= -1) {
    pagerItemClassName = `${pagerItemClassName || ''} carousel-pager-item`
  }

  const firstChildRef = React.useRef()
  const childrenRef = React.useRef(children)
  const [carouselData, setCarouselData] = React.useState(null)

  // Store children inside a ref as ScrollData callback is not refreshed
  childrenRef.current = children

  const [refRailScrollData, setRefRailScrollData] = useScrollData((scrollData) => {
    let firstChildRect = null
    let pageWidth = 0
    let itemWidth = 0
    let itemGap = 0
    let pageSize = 0
    let pageCount = 0
    let firstVisibleIndex = 0
    let lastVisibleIndex = 0
    let currentPageIndex = 0

    if (firstChildRef.current && (!carouselData || scrollData.rect.width !== carouselData.rect.width)) {
      firstChildRect = firstChildRef.current.getBoundingClientRect()
    }

    if (scrollData && firstChildRect && childrenRef.current && childrenRef.current.length > 0) {
      const scrollValue = scrollData.x.value
      pageWidth = scrollData.rect.width
      itemWidth = firstChildRect.width
      pageSize = Math.floor(pageWidth / itemWidth)
      itemGap = (pageWidth - (itemWidth * pageSize)) / (pageSize + 1)
      pageCount = Math.ceil(childrenRef.current.length / pageSize)
      firstVisibleIndex = Math.floor(scrollValue / itemWidth)
      lastVisibleIndex = firstVisibleIndex + pageSize - 1
      currentPageIndex = lastVisibleIndex < childrenRef.current.length - 1 ? Math.floor(firstVisibleIndex / pageSize) : pageCount - 1

      setCarouselData({
        ...scrollData,
        itemWidth,
        pageWidth,
        pageSize,
        itemGap,
        pageCount,
        currentPageIndex
      })
    }
  })

  const childrenWithExtraProp = React.Children.map(children, (child, index) => {
    if (!child) return null
    const extraProps = {}

    if (index === 0) {
      extraProps.ref = firstChildRef
    }
    return React.cloneElement(child, extraProps)
  })

  React.useEffect(() => {
    if (isSlideShow) {
      const timer = setInterval(() => {
        if (carouselData) {
          const newPageIndex = carouselData.currentPageIndex >= carouselData.pageCount - 1 ? 0 : carouselData.currentPageIndex + 1

          refRailScrollData.current.scrollTo({
            left: carouselData.pageWidth * newPageIndex - (carouselData.itemGap * newPageIndex),
            behavior: 'smooth'
          })
        }
      }, slideShowInterval || 1000)
      // clearing interval
      return () => clearInterval(timer)
    }
    // eslint-disable-next-line
  }, [isSlideShow, slideShowInterval, carouselData])

  return (
    <>
      <div className={className} {...rest} ref={setRefRailScrollData}>
        {childrenWithExtraProp}
      </div>

      {
          children && carouselData &&
            <div className={pagerClassName}>
              {
                children.length > carouselData.pageSize && children.map((child, index) => {
                // const extraClass = index >= firstVisibleIndex && index <= lastVisibleIndex ? 'is-current' : ''
                  const extraClass = carouselData && index === carouselData.currentPageIndex * carouselData.pageSize ? 'is-current' : ''

                  if (!carouselData || index % carouselData.pageSize !== 0) return null

                  const pageIndex = Math.floor(index / carouselData.pageSize)

                  return (
                    <div
                      key={index} className={`${pagerItemClassName} ${extraClass}`}
                      onClick={() => {
                        refRailScrollData.current.scrollTo({
                          left: carouselData.pageWidth * pageIndex - (carouselData.itemGap * pageIndex),
                          behavior: 'smooth'
                        })
                      }}
                    />
                  )
                })
            }
            </div>
      }
    </>
  )
}

export default Carousel
