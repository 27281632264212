import React from 'react'
import { LazySection } from '..'
import { DataLink, RichTextField, FirstParagraph } from '../prismic-elements'
import { useLocation, useParams, Link as RouterLink } from 'react-router-dom'
import { client, slicesFetchLinks } from '../../utils/prismicHelpers'
import { getPathWithCurrentLang } from '../../utils/helpers'
import Prismic from 'prismic-javascript'

import './NewsList.sass'

const NewsList = ({ slice }) => {
  const [prismicData, setPrismicData] = React.useState({ news: null })
  const [pageError, setPageError] = React.useState(null)
  const { pathname } = useLocation()
  const { lang } = useParams()

  React.useEffect(() => {
    const fetchPrismicData = async () => {
      let error
      try {
        const news = await client.query(
          Prismic.Predicates.at('document.type', 'news'),
          { lang, orderings: '[document.last_publication_date desc]', fetchLinks: slicesFetchLinks, pageSize: 3 }
        )

        if (news) {
          setPrismicData({ news: news.results })
        } else {
          error = 'Document was not found. Make sure it exists in your Prismic repository.'
          console.warn(error)
        }
      } catch (ex) {
        error = ex
        console.error(error)
      } finally {
        setPageError(error)
      }
    }

    fetchPrismicData()
    // eslint-disable-next-line
  }, [pathname])

  if (pageError) return null

  return (
    <LazySection className={`section section-news slice-news-list ${slice.slice_label || ''}`}>
      <div className='w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <div className='section-news-container'>
          {
            prismicData.news && prismicData.news.map((item, index) => {
              return (
                <DataLink
                  prismicLink={item}
                  key={index}
                  className='section-news-cards-item'
                  style={{ '--background': `url(${item.data.thumbnail && item.data.thumbnail.url})` }}
                >
                  <RichTextField field={item.data.title} Component='h3' />
                  <FirstParagraph className='section-news-cards-item-text' richText={item.data.description} textLimit={140} />
                </DataLink>
              )
            })
          }
        </div>
        {
          slice.primary.cta_label &&
            <RouterLink to={getPathWithCurrentLang(lang, '/news')} className='button w-inline-block'>
              <div>{slice.primary.cta_label}</div>
            </RouterLink>
        }
      </div>
    </LazySection>
  )
}

export default NewsList
