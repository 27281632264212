import React from 'react'
import { Link as RouterLink, NavLink } from 'react-router-dom'
import { Link as PrismicLink } from 'prismic-reactjs'
import { linkResolver } from '../../prismic-configuration'
import { getTypeFormUrlWithParams, openTypeFormPopup } from '../../utils/helpers'

const DataLink = React.forwardRef(({ prismicLink, children, ...rest }, ref) => {
  if (prismicLink && (prismicLink.link_type === 'Document' || prismicLink.type)) {
    if (prismicLink.id) {
      const Component = rest.activeClassName ? NavLink : RouterLink
      const to = prismicLink.link_type ? PrismicLink.url(prismicLink, linkResolver) : linkResolver(prismicLink)

      return <Component ref={ref} {...rest} to={to}>{children}</Component>
    }
    const props = { ...rest }
    delete props.exact
    delete props.activeClassName

    return <span {...props}>{children}</span>
  }

  const props = { ...rest }
  delete props.exact
  delete props.activeClassName

  props.href = prismicLink && prismicLink.url

  if (prismicLink && prismicLink.url && prismicLink.url.indexOf('.typeform.com/to/') > -1) {
    props.href = getTypeFormUrlWithParams(prismicLink.url)
    props.onClick = (e) => {
      e.preventDefault()
      openTypeFormPopup(e.currentTarget.href)
    }
  }

  return (
    <a
      ref={ref}
      target={prismicLink && prismicLink.target ? prismicLink.target : ''}
      rel={prismicLink && prismicLink.target ? 'noopener' : ''}
      {...props}
    >
      {children}
    </a>
  )
})

export default DataLink
