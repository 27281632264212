import { useLocation } from 'react-router-dom'
import { RichText } from 'prismic-reactjs'
import datastore from '../datastore'
import { linkResolver } from '../prismic-configuration'
import { Crawler } from 'es6-crawler-detect'

export const getCookie = (name) => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : null
}

export const setCookie = (name, value, days = [365]) => {
  const d = new Date()
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
}

export const deleteCookie = (name) => { setCookie(name, '', -1) }

const CrawlerDetector = new Crawler()
const isBot = CrawlerDetector.isCrawler(navigator.userAgent)

export const redirectToUserLang = (lang, page, search) => {
  let userLang = getUserLang()
  let browserLang = navigator.language.toLowerCase()

  // Check if browser language is set with no region like just 'fr' or 'en'
  if (browserLang.indexOf('-') < 0) {
    const browserLangWithRegion = datastore.apiDoc.languages.map(_ => _.id).find(_ => _.split('-')[0] === browserLang)
    browserLang = browserLangWithRegion || 'fr-FR'
  }

  // "userLang = 'default'" is when user set en-us lang
  if (userLang === 'default') userLang = 'fr-FR'

  // Check only if user is on canonical
  if (!isBot && !lang) {
    let langToRedirect = null
    // Test if no language set by user, so automatic mode, and if page lang corresponding to browser lang
    if (userLang === null && page.lang !== browserLang) {
      langToRedirect = browserLang
    // or if language is set by user but current page lang not corresponding to user lang
    } else if (userLang && page.lang !== userLang) {
      langToRedirect = userLang
    }
    // Check if this page is available in the lang requested
    if (langToRedirect && datastore.layoutDoc.alternate_languages.find(_ => _.lang === langToRedirect) && page.alternate_languages.find(_ => _.lang === langToRedirect)) {
      const al = page.alternate_languages.find(_ => _.lang === langToRedirect)
      return linkResolver(al) + search
    }
  }

  return null
}

export const getUserLang = () => {
  return getCookie('langage')
}

export const setUserLang = (lang) => {
  setCookie('langage', 'fr-FR')
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const getPathWithCurrentLang = (lang, path) => {
  return (lang ? '/' + lang : '') + path
}

export const createRegexLang = () => /^\/([a-z]{2}-[^/]{2,})(\/|$)/g

export const getLang = (path) => {
  const matchLang = createRegexLang().exec(path)
  return matchLang ? matchLang[1] : null
}

export const socialShares = {
  twitter: (link = '', message = '') =>
      `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
        message
      )}&url=${encodeURIComponent(link)}`,
  facebook: (link = '') =>
      `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`,
  google: (link = '') =>
      `https://plus.google.com/share?url=${encodeURIComponent(link)}`,
  tumblr: (link = '') =>
      `http://tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(
        link
      )}`,
  reddit: (link = '') =>
      `https://reddit.com/submit/?url=${encodeURIComponent(link)}`,
  whatsapp: (link = '', message = '') =>
      `whatsapp://send?text=${encodeURIComponent(message)}%20${encodeURIComponent(
        link
      )}`,
  telegram: (link = '', message = '') =>
      `https://telegram.me/share/url?text=${encodeURIComponent(
        message
      )}&url=${encodeURIComponent(link)}`,
  vk: (link = '', message = '') =>
      `http://vk.com/share.php?title=${encodeURIComponent(
        message
      )}&url=${encodeURIComponent(link)}`,
  hacker: (link = '', message = '') =>
      `https://news.ycombinator.com/submitlink?u=${encodeURIComponent(
        link
      )}&t=${encodeURIComponent(message)}`,
  xing: (link = '', message = '') =>
      `https://www.xing.com/app/user?op=share;url=${encodeURIComponent(
        link
      )};title=${encodeURIComponent(message)}.`,
  mail: (link = '', subject, body) =>
      `mailto:?subject=${encodeURIComponent(
        subject || ''
      )}&body=${encodeURIComponent((body && `${body}\n\n${link}`) || link)}`,
  pinterest: (link = '', message = '') =>
      `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
        link
      )}&media=${encodeURIComponent(link)}&description=${encodeURIComponent(
        message
      )}`,
  linkedin: (link = '', message = '') =>
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        link
      )}&title=${encodeURIComponent(message)}&summary=${encodeURIComponent(
        message
      )}&source=${encodeURIComponent(link)}`
}

export const trackCurrentPage = () => {
  window.gtag && window.gtag('config', window.GA_MEASUREMENT_ID, { page_path: window.location.pathname })
}

export const getApplyJobOfferLabel = () => {
  return datastore.layoutDoc.data.job_offer[0].cta_apply_label
}

export const getTypeFormUrlWithParams = (url) => {
  const query = new URLSearchParams()
  query.set('filiale', 'AXILEO')
  query.set('source', datastore.ref || '')
  return url + '?' + query.toString()
}

export const getTypeFormUrlApplyJobOffer = (jobOffer) => {
  const query = new URLSearchParams()
  query.set('source', datastore.ref || '')
  query.set('offre_id', jobOffer.uid)
  query.set('offre_lib', RichText.asText(jobOffer.data.title))
  query.set('email_recruteur', jobOffer.data.recruiter_email || '')
  query.set('filiale', jobOffer.data.subsidiary || '')
  query.set('secteur', jobOffer.data.sector.uid || '')

  return datastore.layoutDoc.data.job_offer[0].cta_apply_link.url + '?' + query.toString()
}

export const openTypeFormPopup = (url) => {
  window.typeformEmbed.makePopup(url, {
    mode: 'popup',
    autoOpen: true
  })
}
