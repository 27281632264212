import React from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
import { apiEndpoint } from './prismic-configuration'
import DefaultLayout from './components/layout/DefaultLayout'

const HomePage = React.lazy(() => import('./pages/HomePage'))

const SectorPage = React.lazy(() => import('./pages/SectorPage'))
const SolutionPage = React.lazy(() => import('./pages/SolutionPage'))

const JobOfferHomePage = React.lazy(() => import('./pages/JobOfferHomePage'))
const JobOfferPage = React.lazy(() => import('./pages/JobOfferPage'))
// const JobPage = React.lazy(() => import('./pages/JobPage'))

const ProjectHomePage = React.lazy(() => import('./pages/ProjectHomePage'))
const ProjectPage = React.lazy(() => import('./pages/ProjectPage'))

const TestimonialHomePage = React.lazy(() => import('./pages/TestimonialHomePage'))
const TestimonialPage = React.lazy(() => import('./pages/TestimonialPage'))

const NewsHomePage = React.lazy(() => import('./pages/NewsHomePage'))
const NewsPage = React.lazy(() => import('./pages/NewsPage'))

const ContentPage = React.lazy(() => import('./pages/ContentPage'))

const Preview = React.lazy(() => import('./pages/Preview'))
const NotFound = React.lazy(() => import('./pages/NotFound'))

const App = () => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint)
  const repoName = repoNameArray[1]

  return (
    <>
      <Helmet>
        <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
      </Helmet>
      <BrowserRouter>
        {/* Switch is here only to force Render of DefaultLayout when lang change */}
        <Switch>
          <DefaultLayout>
            <React.Suspense fallback={<span />}>
              <Switch>
                <Route exact path='/preview' component={Preview} />

                <Route exact path='/' component={HomePage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})' component={HomePage} />

                <Route exact path='/sectors/:uid' component={SectorPage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/sectors/:uid' component={SectorPage} />

                <Route exact path='/solutions/:uid' component={SolutionPage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/solutions/:uid' component={SolutionPage} />

                <Route exact path='/company/:uid'><ContentPage prismicType='company_page' /></Route>
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/company/:uid'><ContentPage prismicType='company_page' /></Route>

                <Route exact path='/offices/:uid'><ContentPage prismicType='office_page' /></Route>
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/offices/:uid'><ContentPage prismicType='office_page' /></Route>

                <Route exact path='/applicants/jobs' component={JobOfferHomePage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/applicants/jobs' component={JobOfferHomePage} />

                <Route exact path='/applicants/jobs/:uid' component={JobOfferPage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/applicants/jobs/:uid' component={JobOfferPage} />

                <Route exact path='/applicants/:uid'><ContentPage prismicType='applicant_page' /></Route>
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/applicants/:uid'><ContentPage prismicType='applicant_page' /></Route>

                {/* <Route exact path='/applicants/careers/:uid' component={JobPage} />
              <Route exact path='/:lang([a-z]{2}-[^/]{2,})/applicants/careers/:uid' component={JobPage} /> */}

                <Route exact path='/expertises/projects' component={ProjectHomePage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/expertises/projects' component={ProjectHomePage} />

                <Route exact path='/expertises/projects/:uid' component={ProjectPage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/expertises/projects/:uid' component={ProjectPage} />

                <Route exact path='/expertises/testimonials' component={TestimonialHomePage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/expertises/testimonials' component={TestimonialHomePage} />

                <Route exact path='/expertises/testimonials/:uid' component={TestimonialPage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/expertises/testimonials/:uid' component={TestimonialPage} />

                <Route exact path='/expertises/:uid'><ContentPage prismicType='expertise_page' /></Route>
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/expertises/:uid'><ContentPage prismicType='expertise_page' /></Route>

                <Route exact path='/news' component={NewsHomePage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/news' component={NewsHomePage} />

                <Route exact path='/news/:uid' component={NewsPage} />
                <Route exact path='/:lang([a-z]{2}-[^/]{2,})/news/:uid' component={NewsPage} />

                <Route component={NotFound} />
              </Switch>
            </React.Suspense>
          </DefaultLayout>
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
