import React from 'react'
import useInOutTransition, { TransitionStage } from '../utils/useInOutTransition'

const getTransitionClassName = (classNames, transitionState) => {
  let name = ''

  switch (transitionState) {
    case TransitionStage.entering:
      name = name.concat(classNames, '-enter')
      break
    case TransitionStage.entered:
      name = name.concat(classNames, '-enter', ' ', classNames, '-enter-active')
      break
    case TransitionStage.exiting:
      name = name.concat(classNames, '-exit', ' ', classNames, '-exit-active')
      break
    case TransitionStage.exited:
      name = name.concat(classNames, '-exit')
      break
    default:
      break
  }

  return name
}

const CSSTransitionContainer = ({ children, className, classNames, timeout, unmountOnExit, isIn, ...rest }) => {
  const transitionState = useInOutTransition(isIn, timeout)

  return (
    (!unmountOnExit || (unmountOnExit && transitionState !== TransitionStage.exited)) &&
      <div className={className + ' ' + getTransitionClassName(classNames, transitionState)} {...rest}>{children}</div>
  )
}

export default CSSTransitionContainer
