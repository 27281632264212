import React from 'react'
import { Carousel, LazySection } from '..'
import { RichTextField, DataLink } from '../prismic-elements'
import { animated } from 'react-spring'
import { useTiltEffect } from '../../utils/useTiltEffect'

import './Cards.sass'

const Cards = ({ slice }) => {
  return (
    <LazySection className='section section-cards slice-cards'>
      <div className='w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <Carousel className='section-cards-container' pagerClassName='pager' pagerItemClassName='pager-item'>
          {
            slice.items.map((item, index) => {
              return (
                <DataLink
                  key={index}
                  prismicLink={item.cta_link}
                  style={{ '--background': `url(${item.thumbnail.url})` }}
                >
                  <Card item={item} />
                </DataLink>
              )
            })
          }
        </Carousel>
      </div>
    </LazySection>
  )
}

const Card = ({ item }) => {
  const ref = React.useRef(null)
  const { style, ...mouseHandlers } = useTiltEffect(ref)

  return (
    <animated.div
      ref={ref}
      style={style}
      {...mouseHandlers}
      className='section-cards-item'
    >
      <RichTextField field={item.title} Component='h3' />
      <span className='section-cards-item-link w-inline-block'>
        <div>{item.cta_label}</div>
      </span>
    </animated.div>
  )
}

export default Cards
