import React from 'react'
import { LazySection } from '..'
import { CountUp } from 'countup.js'
import { v4 as uuidv4 } from 'uuid'

const Numbers = ({ slice }) => {
  const [sectionInView, setSectionInView] = React.useState(false)

  return (
    <LazySection className='section section-numbers' inViewCallback={(inView) => { setSectionInView(inView) }}>
      <div className='is-centered w-container'>
        <div className='section-numbers-list'>
          {
            slice.items.map((item, index) => (
              <div className='section-numbers-list-item' key={index}>
                <Number inView={sectionInView} value={item.number} />
                <div className='section-numbers-list-item-text'>{item.label}</div>
              </div>
            ))
          }
        </div>
      </div>
    </LazySection>
  )
}

const Number = ({ inView, value }) => {
  const [uniqueId] = React.useState(uuidv4())

  React.useEffect(() => {
    if (inView && value) {
      const match = value.match(/(\D*)(\d[\d\s.,]*)(\D*)/)
      let prefix = ''
      let suffix = ''
      let endValue = value.replace(/\s/g, '')

      if (match && match[2].indexOf('.') === -1) {
        if (match[0] !== match[2]) {
          prefix = match[1]
          endValue = match[2]
          suffix = match[3]
        }

        const countUp = new CountUp(uniqueId, endValue, { prefix, suffix, useGrouping: false })
        countUp.start()
      }
    }
  }, [value, uniqueId, inView])

  return (
    <div id={uniqueId} className='section-numbers-list-item-number'>{value}</div>
  )
}

export default Numbers
