import React from 'react'
import { RichTextField, DataLink } from '../prismic-elements'
import { LazySection } from '..'

import './VisualAndFeaturedItems.sass'

const VisualAndFeaturedItems = ({ slice }) => {
  const visualStyle = slice.primary.visual_background.url ? { backgroundImage: `url(${slice.primary.visual_background.url})` } : null

  return (
    <LazySection className='section section-visual-and-featured-items'>
      <div className='section-visual-and-featured-items-container w-container is-full'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <div className='section-visual-and-featured-items-container-inner'>
          <div className='section-visual-and-featured-items-visual' style={visualStyle}>
            {
              slice.primary.visual_image.url &&
                <img
                  src={slice.primary.visual_image.url}
                  loading='lazy'
                  alt={slice.primary.visual_image.alt}
                  width={slice.primary.visual_image.dimensions.width}
                  height={slice.primary.visual_image.dimensions.height}
                  className='section-visual-and-featured-items-visual-image'
                />
            }
          </div>
          <div className='section-visual-and-featured-items-list'>
            {
              slice.items.map((item, index) => (
                <div className='section-visual-and-featured-items-list-item' key={index}>
                  <RichTextField field={item.title} Component='h3' />
                  <RichTextField field={item.description} Component='div' className='section-visual-and-featured-items-list-item-text' />
                  <DataLink prismicLink={item.cta_link} className='button w-inline-block'>
                    <div>{item.cta_label}</div>
                  </DataLink>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </LazySection>
  )
}

export default VisualAndFeaturedItems
