import React from 'react'
import { DataLink } from '../components/prismic-elements'
import Prismic from 'prismic-javascript'
import { apiEndpoint, accessToken } from '../prismic-configuration'

export const getJobOfferDetail = (jobOffer) => {
  let detail = ''
  if (jobOffer.data.location && jobOffer.data.location.data) detail += jobOffer.data.location.data.name
  if (detail !== '' && jobOffer.data.contract_type) detail += ' | '
  if (jobOffer.data.contract_type) detail += jobOffer.data.contract_type
  return detail
}

export const slicesFetchLinks = [
  //
  'location.name',
  //
  'job.name',
  'job.title',
  'job.thumbnail',
  'job.hero_background',
  //
  'sector.name',
  'sector.title',
  'sector.description',
  'sector.thumbnail',
  'sector.hero_background',
  //
  'office_page.title',
  //
  'solution.name',
  'solution.title',
  'solution.description',
  'solution.thumbnail',
  'solution.hero_background',
  'solution.embed',
  //
  'project.title',
  'project.hero_background',
  'project.sector',
  //
  'testimonial.title',
  'testimonial.description',
  'testimonial.hero_background',
  'testimonial.embed',
  //
  'news.title',
  'news.description',
  'news.thumbnail',
  'news.hero_background'
]

// Helper function to convert Prismic Rich Text links to React Link components
// RB: I don't know why but no label from Prismic RichText on hyperlink...
export const customLink = (type, element, content, children, index) => {
  return (
    <DataLink prismicLink={element.data} key={index} className={element.label || ''}>
      {content}
    </DataLink>
  )
}

// Client method to query documents from the Prismic repo
export const client = Prismic.client(apiEndpoint, { accessToken })

export const isRichTextEmpty = (richtext) => !richtext || richtext.length === 0 || (richtext.length === 1 && richtext[0].text === '')
