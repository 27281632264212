import React from 'react'
import { RichTextField } from '../prismic-elements'
import { LazySection } from '..'

import './RichText.sass'

const RichText = ({ slice }) => {
  return (
    <LazySection
      className={`section section-richtext ${slice.slice_label || ''}`}
    >
      <div className='is-narrow w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <div className='section-richtext w-richtext'>
          <RichTextField field={slice.primary.rich_text} />
        </div>
      </div>
    </LazySection>
  )
}

export default RichText
