import React from 'react'
import { Redirect } from 'react-router-dom'

const PageError = ({ error }) => {
  const to = error.type && error.type === 'redirect' ? error.to : '/404'

  return (
    <Redirect to={to} />
  )
}

export default PageError
