import React from 'react'
import { RichTextField, DataLink } from '../prismic-elements'
import { LazySection } from '..'

import './RichTextWithBackground.sass'

const RichTextWithBackground = ({ slice }) => {
  const style = slice.primary.image.url ? { '--background': `url(${slice.primary.image.url})` } : null

  return (
    <LazySection
      className={`section section-richtext-with-background ${slice.slice_label || ''}`}
      style={style}
    >
      <div className='is-narrow w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <div className='section-richtext w-richtext'>
          <RichTextField field={slice.primary.rich_text} />
        </div>
        {slice.primary.cta_link.url &&
          <DataLink prismicLink={slice.primary.cta_link} className='button w-inline-block'>
            <div>{slice.primary.cta_label}</div>
          </DataLink>}
      </div>
    </LazySection>
  )
}

export default RichTextWithBackground
