import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../prismic-configuration'
import { customLink, isRichTextEmpty } from '../../utils/prismicHelpers'

/**
 * Helper component for Prismic Rich Text fields
 */
const RichTextField = ({ field, ...rest }) => {
  if (isRichTextEmpty(field)) return null

  return (
    <RichText
      {...rest}
      render={field}
      linkResolver={linkResolver}
      serializeHyperlink={customLink}
    />
  )
}

export default RichTextField
