import React from 'react'
import {
  RichText,
  RichTextWithBackground,
  Numbers,
  LogoGallery,
  VisualAndFeaturedItems,
  SectorsList,
  Solutions,
  Projects,
  Testimonials,
  Features,
  AlternateGrid,
  VisualAndFeature,
  Cards,
  SectorsGrid,
  Targets,
  Quote,
  CurrentJobOffers,
  News,
  NewsList,
  Links,
  Subsidiaries,
  SteppedRichText
} from './slices'

/**
 * Prismic Slice Zone component
 */
const SliceZone = ({ sliceZone }) => (
  <>
    {
      sliceZone && sliceZone.map((slice, index) => {
        switch (slice.slice_type) {
          case ('richtext'):
            return <RichText slice={slice} key={`slice-${index}`} />
          case ('richtext_with_background'):
            return <RichTextWithBackground slice={slice} key={`slice-${index}`} />
          case ('numbers'):
            return <Numbers slice={slice} key={`slice-${index}`} />
          case ('logo_gallery'):
            return <LogoGallery slice={slice} key={`slice-${index}`} />
          case ('visual_and_featured_items'):
            return <VisualAndFeaturedItems slice={slice} key={`slice-${index}`} />
          case ('sectors_list'):
            return <SectorsList slice={slice} key={`slice-${index}`} />
          case ('solutions'):
            return <Solutions slice={slice} key={`slice-${index}`} />
          case ('solutions_detail'):
            return <Solutions slice={slice} key={`slice-${index}`} />
          case ('projects'):
            return <Projects slice={slice} key={`slice-${index}`} />
          case ('testimonials'):
            return <Testimonials slice={slice} key={`slice-${index}`} />
          case ('features'):
            return <Features slice={slice} key={`slice-${index}`} />
          case ('alternate_grid'):
            return <AlternateGrid slice={slice} key={`slice-${index}`} />
          case ('visual_and_feature'):
            return <VisualAndFeature slice={slice} key={`slice-${index}`} />
          case ('cards'):
            return <Cards slice={slice} key={`slice-${index}`} />
          case ('sectors_grid'):
            return <SectorsGrid slice={slice} key={`slice-${index}`} />
          case ('targets'):
            return <Targets slice={slice} key={`slice-${index}`} />
          case ('quote'):
            return <Quote slice={slice} key={`slice-${index}`} />
          case ('current_job_offers'):
            return <CurrentJobOffers slice={slice} key={`slice-${index}`} />
          case ('news'):
            return <News slice={slice} key={`slice-${index}`} />
          case ('news_list'):
            return <NewsList slice={slice} key={`slice-${index}`} />
          case ('links'):
            return <Links slice={slice} key={`slice-${index}`} />
          case ('subsidiaries'):
            return <Subsidiaries slice={slice} key={`slice-${index}`} />
          case ('stepped_richtext'):
            return <SteppedRichText slice={slice} key={`slice-${index}`} />
          default:
            return null
        }
      })
    }
  </>
)

export default SliceZone
