import React from 'react'
import { Carousel, LazySection } from '..'
import { RichTextField } from '../prismic-elements'

import './Features.sass'

const Features = ({ slice }) => {
  return (
    <LazySection className={`section section section-features ${slice.slice_label || ''}`}>
      <div className='w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <Carousel data-count={slice.items.length} className='section-features-container' pagerClassName='pager' pagerItemClassName='pager-item'>
          {
              slice.items.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='section-features-item'
                  >
                    {
                      item.image.url &&
                        <img
                          src={item.image.url}
                          loading='lazy'
                          alt={item.image.alt}
                          width={item.image.dimensions.width}
                          height={item.image.dimensions.height}
                        />
                    }
                    <RichTextField field={item.title} Component='h3' className='section-features-item-title' />
                    <RichTextField field={item.description} Component='div' className='section-features-item-text' />
                  </div>
                )
              })
          }
        </Carousel>
      </div>
    </LazySection>
  )
}

export default Features
