import React from 'react'
import { LazySection, Modal, SubsidiariesMap } from '..'
import { RichTextField, DataLink } from '../prismic-elements'
import { RichText } from 'prismic-reactjs'
import datastore from '../../datastore'

import './Subsidiaries.sass'

function uniqByKeepLast (data, key) {
  return [
    ...new Map(
      data.map(x => [key(x), x])
    ).values()
  ]
}

const Subsidiaries = ({ slice }) => {
  const [officePages, setOfficePages] = React.useState(null)
  const [isModalShown, setIsModalShown] = React.useState(false)
  const subsidiaries = datastore.subsidiaries
  const defaultSubsidiaryUID = slice.primary.default_subsidiary.uid ? slice.primary.default_subsidiary.uid : 'levallois-perret'
  const [selectedSubsidiary, setSelectedSubsidiary] = React.useState(subsidiaries.find(_ => _.uid === defaultSubsidiaryUID))
  const sliceSubsidiariesDetail = datastore.layoutDoc.data.slice_subsidiaries_detail[0]
  const sliceSubsidiariesPopup = datastore.layoutDoc.data.slice_subsidiaries_popup[0]

  React.useEffect(() => {
    const officePages = uniqByKeepLast(datastore.subsidiaries, _ => _.data.office_page.uid).map(_ => _.data.office_page)
    setOfficePages(officePages)
  }, [])

  return (
    <LazySection
      className={`section section-subsidiaries ${slice.slice_label || ''}`}
    >
      <Modal className='modal-subsidiaries' isShown={isModalShown} hide={() => setIsModalShown(false)}>
        <div className='modal-subsidiaries-inner'>
          {
            sliceSubsidiariesPopup.image && sliceSubsidiariesPopup.image.url &&
              <img
                src={sliceSubsidiariesPopup.image.url}
                loading='lazy'
                alt={sliceSubsidiariesPopup.image.alt}
                width={sliceSubsidiariesPopup.image.dimensions.width}
                height={sliceSubsidiariesPopup.image.dimensions.height}
                className='modal-subsidiaries-image'
              />
          }
          <div className='modal-subsidiaries-detail'>
            <RichTextField field={sliceSubsidiariesPopup.title} Component='h2' className='section-title' />
            <RichTextField field={sliceSubsidiariesPopup.description} Component='div' className='modal-subsidiaries-description' />
            <ul>
              {
                officePages && officePages.map((item, index) => {
                  return (
                    <li key={index}>
                      <DataLink prismicLink={item}>{RichText.asText(item.data.title)}</DataLink>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </Modal>
      <div className='section-subsidiaries-map'>
        <SubsidiariesMap
          defaultSubsidiary={selectedSubsidiary && selectedSubsidiary.uid}
          selectSubsidiaryCallback={(item) => setSelectedSubsidiary(subsidiaries.find(_ => _.uid === item.name))}
        />
      </div>
      <div className='section-subsidiaries-container'>
        <div className='w-container'>
          <div className='section-subsidiaries-detail'>
            <RichTextField field={sliceSubsidiariesDetail.title} Component='h3' />
            <div className='section-subsidiaries-detail-name'>
              {selectedSubsidiary && selectedSubsidiary.data.name}
            </div>
            <RichTextField className='section-subsidiaries-detail-address' field={selectedSubsidiary && selectedSubsidiary.data.address} Component='div' />
            {
              selectedSubsidiary && selectedSubsidiary.data.phone &&
                <a href={`tel:${selectedSubsidiary.data.phone}`} className='section-subsidiaries-detail-phone'>
                  {selectedSubsidiary && selectedSubsidiary.data.phone}
                </a>
            }
            {
              selectedSubsidiary && selectedSubsidiary.data.contact &&
                <DataLink prismicLink={selectedSubsidiary.data.contact} className='section-subsidiaries-detail-email'>
                  {sliceSubsidiariesDetail.cta_contact_label}
                </DataLink>
            }
            <div className='section-subsidiaries-detail-ctas'>
              <button className='button w-inline-block' onClick={() => setIsModalShown(true)}>
                <div>{sliceSubsidiariesDetail.cta_all_label}</div>
              </button>
            </div>
          </div>

        </div>
      </div>
    </LazySection>
  )
}

export default Subsidiaries
