import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { DataLink } from '../prismic-elements'
import { getPathWithCurrentLang } from '../../utils/helpers'
import mcaLogo from '../../assets/images/axileo-logo.png'

import './Header.sass'

const Header = ({ layoutDoc }) => {
  const [isMenuOpened, setIsMenuOpened] = React.useState(false)
  const [subMenuMenuOpened, setSubMenuOpened] = React.useState(-1)

  const extraClassHeader = isMenuOpened ? ' is-menu-opened' : ''

  if (layoutDoc) {
    return (
      <header className={'header' + extraClassHeader}>
        <div className='header-navbar-container w-container'>
          <div className='navbar'>
            <div className='navbar-burger'>
              <div
                className='burger-toggle'
                onClick={() => setIsMenuOpened(!isMenuOpened)}
                data-checked={isMenuOpened}
              >
                <div className='line half start' />
                <div className='line' />
                <div className='line half end' />
              </div>
            </div>
            <RouterLink
              to={getPathWithCurrentLang(layoutDoc.currentLangPath, '/')} aria-current='page' className='navbar-brand w-inline-block w--current'
              onClick={() => setIsMenuOpened(false)}
            >
              <img src={mcaLogo} width='72' height='30' loading='lazy' alt='MCA Logo' />
            </RouterLink>
            <ul className='navbar-menu-list'>
              {
                layoutDoc.data.navbar_menu_items.map((menuItem, index) => (
                  <MenuItem
                    menuItem={menuItem}
                    key={'menu-item-' + index}
                    isSubMenuOpened={subMenuMenuOpened === index}
                    openMenu={() => setIsMenuOpened(true)}
                    closeMenu={() => setIsMenuOpened(false)}
                    toggleSubMenu={() => setSubMenuOpened(subMenuMenuOpened === index ? -1 : index)}
                  />
                ))
              }
            </ul>
            <div className='navbar-cta-list'>
              {
                layoutDoc.data.navbar_cta_items.map((ctaItem, index) => (
                  <DataLink
                    key={'cta-item-' + index} prismicLink={ctaItem.link} className='navbar-cta-list-item w-inline-block'
                    style={{ backgroundImage: `url(${ctaItem.image.url})` }}
                    title={ctaItem.label}
                  >
                    <div className='navbar-cta-list-item-label'>{ctaItem.label}</div>
                  </DataLink>
                ))
              }
            </div>
          </div>
        </div>
      </header>
    )
  }
  return null
}

const MenuItem = ({ menuItem, isSubMenuOpened, toggleSubMenu, openMenu, closeMenu }) => {
  const hasSubMenu = menuItem.items && menuItem.items.length > 0 && menuItem.items[0].label

  return (
    <li
      onMouseEnter={openMenu}
      className={'navbar-menu-list-item' + (hasSubMenu ? ' has-sub-menu' : '') + (isSubMenuOpened ? ' is-sub-menu-opened' : '')}
    >
      <DataLink
        exact activeClassName='is-active'
        prismicLink={menuItem.primary.link}
        onClick={() => {
          hasSubMenu ? toggleSubMenu() : closeMenu()
        }}
        className='navbar-menu-list-item-label w-inline-block'
      >
        {menuItem.primary.label}
      </DataLink>
      {
        hasSubMenu &&
          <div className='navbar-sub-menu'>
            <ul className='navbar-sub-menu-list'>
              {menuItem.items.map((subItem, index) => (
                <li className='navbar-sub-menu-list-item' key={'subitem-' + index}>
                  <DataLink
                    exact activeClassName='is-active'
                    prismicLink={subItem.link}
                    onClick={closeMenu}
                    className='navbar-sub-menu-list-item-link w-inline-block'
                  >
                    {subItem.label}
                  </DataLink>
                </li>
              ))}
            </ul>
          </div>
      }
    </li>
  )
}

export default Header
