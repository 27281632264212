import React from 'react'
import { RichTextField, DataLink } from '../prismic-elements'
import { LazySection } from '..'

import './Links.sass'

const Links = ({ slice }) => {
  return (
    <LazySection
      className={`section section-links ${slice.slice_label || ''}`}
    >
      <div className='is-narrow w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <RichTextField field={slice.primary.description} Component='div' className='section-links-description' />
        <div className='section-links-container'>
          {
            slice.items.map((item, index) => {
              return (
                <DataLink key={index} prismicLink={item.cta_link} className='button-icon-text icon-download w-inline-block'>
                  <div>{item.cta_label}</div>
                </DataLink>
              )
            })
          }
        </div>
      </div>
    </LazySection>
  )
}

export default Links
