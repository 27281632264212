import React from 'react'
import { Carousel, LazySection } from '..'
import { RichTextField, DataLink } from '../prismic-elements'

import './Projects.sass'

const Projects = ({ slice }) => {
  return (
    <LazySection className='section section-cards-full slice-projects'>
      <div className='is-full w-container'>
        <Carousel className='section-cards-full-container' pagerClassName='pager section-cards-full-pager' pagerItemClassName='pager-item'>
          {
            slice.items.map((item, index) => {
              const projectData = item.project && item.project.data
              return (
                <div
                  key={index}
                  className='section-cards-full-item'
                  style={{ '--background': `url(${projectData && projectData.hero_background && projectData.hero_background.url})` }}
                >
                  <RichTextField field={slice.primary.title} Component='h3' className='section-cards-full-item-subtitle' />
                  <RichTextField field={projectData && projectData.title} Component='h2' className='section-cards-full-item-title' />
                  <DataLink prismicLink={item.project} className='section-cards-full-item-link w-inline-block'>
                    <div>{item.cta_label}</div>
                  </DataLink>
                </div>
              )
            })
          }
        </Carousel>
      </div>
    </LazySection>
  )
}

export default Projects
