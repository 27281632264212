import React from 'react'
import { Carousel, Modal, LazySection } from '..'
import { RichTextField, DataLink } from '../prismic-elements'

import './Testimonials.sass'

const Testimonials = ({ slice }) => {
  const [currentVideoItem, setCurrentVideoItem] = React.useState(null)
  return (
    <LazySection className={`section section-cards-full slice-testimonials ${slice.slice_label || ''}`}>
      <Modal isShown={currentVideoItem !== null} hide={() => setCurrentVideoItem(null)}>
        {
          currentVideoItem && <div data-oembed-provider={currentVideoItem.data.embed.provider_name} dangerouslySetInnerHTML={{ __html: currentVideoItem.data.embed.html }} />
        }
      </Modal>
      <div className='is-full w-container'>
        <Carousel className='section-cards-full-container' pagerClassName='pager section-cards-full-pager' pagerItemClassName='pager-item'>
          {
            slice.items.filter(_ => _.testimonial && _.testimonial.id && _.testimonial.data).map((item, index) => {
              const testimonial = item.testimonial
              const hasVideo = testimonial.data.embed && testimonial.data.embed.embed_url
              return (
                <div
                  key={index}
                  className='section-cards-full-item'
                  style={{ '--background': `url(${testimonial.data.hero_background.url})` }}
                >
                  {
                  hasVideo &&
                    // eslint-disable-next-line
                    <a
                      href={testimonial.data.embed.embed_url}
                      aria-label={testimonial.data.embed.title}
                      onClick={(e) => {
                        e.preventDefault()
                        setCurrentVideoItem(testimonial)
                      }}
                      className='button-icon button-icon-play w-inline-block'
                    />
                  }
                  {
                    !hasVideo && <div className='icon-quote' />
                  }

                  <RichTextField field={testimonial.data.title} Component='h3' className='section-cards-full-item-subtitle' />
                  <RichTextField field={testimonial.data.description} Component='h2' className='section-cards-full-item-title' />
                  <DataLink prismicLink={item.testimonial} className='section-cards-full-item-link w-inline-block'>
                    <div>{item.cta_label}</div>
                  </DataLink>
                </div>
              )
            })
          }
        </Carousel>
      </div>
    </LazySection>
  )
}

export default Testimonials
